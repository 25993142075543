import './foundation';
import './nav';
import './accordion';
import './slides/index.js';
import './slides/slideshow.js';
import * as $ from 'jquery';
import 'slick-carousel/slick/slick.min.js';
import 'waypoints/lib/jquery.waypoints.min.js';
import './waypoints.js';

$(document).ready(function () {

  // Write JavaScript & jQuery here
  $('.carousel').slick({
    infinite: true,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });

  $('.testimonial-carousel').slick({
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
  });
})
